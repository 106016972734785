import React from "react"

const TableTitle = ({ text, className = "" }) => (
  <div
    className={`grid items-center font-bold uppercase border-b border-primary text-sm md:text-lg text-center md:text-left ${className}`}
  >
    {text}
  </div>
)

const TableItem = ({ text = "", className = "", url = "" }) => {
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={`text-sm md:text-base underline hover:text-fifth visited:text-purple-500 ${className}`}
      >
        {text}
      </a>
    )
  } else {
    return <div className={`text-sm md:text-base ${className}`}>{text}</div>
  }
}

// For mobile, make an alternate "table in which items are stacked? or just smaller fonts?"

const ServicesTable = ({ services = [] }) => {
  return (
    <>
      {/* Desktop chart view */}
      <div className="hidden md:grid gap-x-5 grid-cols-3 border border-primary px-2 pt-0 pb-2 shadow-md mx-10 ">
        <TableTitle text="Name" />
        <TableTitle text="Address" />
        <TableTitle text="Phone Number" />
        {services.map((service, index) => (
          <>
            <TableItem
              key={`${index}-${service.title}`}
              text={service?.title || ""}
              className="mt-2"
              url={service.website}
            />
            <TableItem
              key={`${index}-${service.title}`}
              text={service?.address || ""}
              className="mt-2"
            />
            <TableItem
              key={`${index}-${service.title}`}
              text={service?.phone || ""}
              className="mt-2 "
            />

            {/* {index % 3 == 0 && <div className="col-span-3 border"></div>} */}
          </>
        ))}
      </div>
      {/* Mobile Chart view */}
      <div className="border border-primary grid grid-flow-row md:hidden px-2 pt-0 pb-2 shadow-md mx-5  ">
        {services.map((service, index) => (
          <div className="mt-3">
            <TableItem
              key={`${index}-${service.title}`}
              text={service?.title}
              className=" font-bold"
              url={service.website}
            />
            {service?.address ? (
              <TableItem
                key={`${index}-${service.title}`}
                text={service?.address}
                className="italic"
              />
            ) : null}
            <TableItem
              className=""
              key={`${index}-${service.title}`}
              text={service?.phone}
            />
            {/* {index % 3 == 0 && <div className="col-span-3 border"></div>} */}
          </div>
        ))}
      </div>
    </>
  )
}

export default ServicesTable
