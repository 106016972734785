import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/SEO"

import HeroContainer from "../../components/heroContainer"
import PageTitle from "../../components/PageTitle"
import ServicesTable from "../../components/services/ServicesTable"
import { AiOutlineLeft } from "react-icons/ai"
import ButtonLink from "../../components/ButtonLink"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../../sanityConfig"
import StandardContainer from "../../components/containers/StandardContainer"

const EmphText = ({ text = "TEXT HERE" }) => (
  <p className="text-4xl font-heading text-center my-8">{text}</p>
)

const Services = ({ data }) => {
  const { sanityServicesPage } = data

  const defaultHeroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBannerImage.childImageSharp.fluid

  const fluidBanner =
    getFluidGatsbyImage(
      sanityServicesPage?.rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || defaultHeroFluid

  const imageCards = [
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
    {
      image: defaultHeroFluid,
      community: "Community Name",
      website: "View Website",
    },
  ]
  const heroProps = {
    fluid: fluidBanner,

    gradient: true,
  }

  return (
    <Layout>
      <SEO title="Services" />
      <HeroContainer props={heroProps} />

      <StandardContainer className="px-2 md:px-5 py-10 ">
        <div className="grid  justify-items-start">
          <ButtonLink
            to="/lifestyle"
            className="flex items-center gap-x-2 uppercase tracking-wide my-5 "
          >
            <AiOutlineLeft /> Lifestyle Page
          </ButtonLink>
        </div>
        <PageTitle text={sanityServicesPage.title} />
        <p className="text-center mt-5">
          Sioux Lookout offers many conveniences. See below for a list of
          services and their contact information.
        </p>

        <section className=" mt-10 grid gap-y-10  ">
          {/* <ServicesTable services={sanityServicesPage} /> */}
          {sanityServicesPage.serviceTypes.map((serviceType, index) => {
            {
              /* Only return a list if there are one or more entries */
            }
            if (serviceType.serviceList.length >= 1) {
              return (
                <div>
                  <h2 className="mb-4 text-quaternary">{serviceType.title}</h2>
                  <ServicesTable services={serviceType.serviceList} />
                </div>
              )
            }
          })}
        </section>
      </StandardContainer>
    </Layout>
  )
}
export default Services

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "lifestyle_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBannerImage: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanityServicesPage {
      _id
      title
      rawHeaderImage: _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      serviceTypes {
        title
        serviceList {
          title
          phone
          address
          website
        }
      }
    }
  }
`
